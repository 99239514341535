<template>
  <div>
    <!-- <Navbar /> -->
    <div class="text-center mb-4 fn24-weight7" style="color: #1a347f">
      My Profile
    </div>
    <!-- <img
        src="@/assets/biah-member-club-logo.png"
        style="display: block; margin-left: auto; margin-right: auto"
        width="100"
    /> -->
    <v-row class="mt-4" align="center" justify="center">
      <v-avatar size="100" class="mt-2 mb-6">
        <v-img
          src="@/assets/biah-member-club-logo.png"
          style="display: block; margin-left: auto; margin-right: auto"
          width="100px"
      /></v-avatar>

      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <v-row class="fn14 head-color ma-1 mb-0">
          Name
          <v-spacer></v-spacer>
          <v-icon @click="(change = true), getUser()" v-if="!change"
            >mdi-pencil</v-icon
          >
        </v-row>
      </v-col>
      <v-col cols="12" class="pt-0" v-if="!change">
        <div class="fn16 sub-color" style="font-weight: 600">
          {{ userData.firstname }} {{ userData.lastname }}
        </div>
      </v-col>
      <v-col cols="6" class="pt-0" v-if="change">
        <div class="fn16 sub-color" style="font-weight: 600">
          <v-text-field
            v-model="firstname"
            label="Firstname"
            outlined
            dense
          ></v-text-field>
          <!-- @change="updateName()" -->
        </div>
      </v-col>
      <v-col cols="6" class="pt-0" v-if="change">
        <div class="fn16 sub-color" style="font-weight: 600">
          <v-text-field
            v-model="lastname"
            label="Lastname"
            outlined
            dense
          ></v-text-field>
          <!-- @change="updateName()" -->
        </div>
      </v-col>

      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <div class="fn14 head-color">Your BI Points</div>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div class="fn16 sub-color" style="font-weight: 600">
          {{ userData.bi_current_point }} Points
        </div>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12">
        <div class="fn14 head-color">Your CE Points</div>
      </v-col>
      <v-col cols="12" class="pt-0">
        <div class="fn16 sub-color" style="font-weight: 600">
          {{ userData.ce_current_credit }} Points
        </div>
      </v-col>
      <v-col cols="12" class="pa-0">
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" class="mt-6 text-center">
        <v-btn text color="red" @click="dialogUnsub = true"
          >Unsubscribe the membership</v-btn
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="6" class="text-center">
        <v-btn
          class="text-left"
          @click="(change = false), getUser()"
          dark
          v-if="change"
          small
          style="background: linear-gradient(268.1deg, #f5222d, #ff000d)"
          >cancel</v-btn
        >
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="6" class="text-center">
        <v-btn
          @click="changeName()"
          dark
          v-if="change"
          small
          style="background: linear-gradient(268.1deg, #07204b, #1a347f)"
          >save</v-btn
        >
      </v-col>
    </v-row>
    <v-row style="position: absolute; bottom: 0; width: 100%; height: 47px">
      <v-footer
        width="100%"
        padless
        class="pa-2 ma-0"
        style="
          background: #f6f6f6;
          color: #1a347f;
          font-weight: 600;
          font-size: 10px;
        "
      >
        <v-col class="text-center pa-0" cols="12">
          © 2010-2021 Boehringer Ingelheim International GmbH. All rights
          reserved.
        </v-col>
      </v-footer>
      <v-dialog v-model="dialogUnsub">
        <v-card class="pa-4 rounded-lg">
          <!-- royalty_status -->
          <v-row align="center" justify="center" class="text-center">
            <v-col cols="3" class="mt-6"
              ><v-img src="@/assets/Error.png"></v-img
            ></v-col>
            <v-col cols="12">
              <span style="font-size: 22px; color: #eb5757; font-weight: 900"
                >Are you sure to unsubscribe the membersip?</span
              >
            </v-col>
            <v-col cols="12">
              <span style="font-size: 14px"
                >You account will be signed out and if want to subscribe you
                need to register again.</span
              >
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="text-center">
            <v-col cols="12" class="mt-6 text-center">
              <v-btn
                block
                outlined
                height="57"
                color="red"
                @click="unSubscribe()"
                >Yes, I’m sure</v-btn
              >
            </v-col>
            <v-col cols="12" class="text-center">
              <v-btn
                height="57"
                outlined
                block
                dark
                class="rounded-lg fn16-weight7 donut"
                @click="dialogUnsub = false"
                >Back to Profile</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogCheckbox" fullscreen>
        <v-card class="rounded-lg">
          <v-row align="center" justify="center" class="pa-6">
            <v-col cols="12"></v-col>
            <v-col cols="12"></v-col>
            <v-col cols="12"></v-col>
            <v-col cols="12"></v-col>
            <v-col cols="8">
              <v-img src="@/assets/logo_regis.png"></v-img>
            </v-col>
            <v-col cols="12">
              <v-checkbox
                v-model="check1"
                label="Priviledge Membership Program"
                color="indigo darken-3"
                readonly
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="check1"
                label="Earn Special points and redeem exclusive rewards"
                color="indigo darken-3"
                readonly
                hide-details
              ></v-checkbox>
              <v-checkbox
                v-model="check1"
                label="New content in E-learning and special Digital Asset Hubs"
                color="indigo darken-3"
                readonly
                hide-details
              ></v-checkbox>
            </v-col>

            <v-col cols="12">
              <span>Register and Explore a lot more!</span>
            </v-col>
          </v-row>
          <v-col cols="12"></v-col>
          <v-row align="center" justify="center" class="text-center">
            <v-col cols="11" class="text-center">
              <v-btn
                height="57"
                outlined
                block
                dark
                class="rounded-lg fn16-weight7 donut"
                @click="goToManageSpecies()"
                >register</v-btn
              >
            </v-col>
          </v-row>
          <v-col cols="12"></v-col>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
// import Navbar from "@/components/nav";
// import footerPage from "@/components/Shared/footer";
export default {
  components: {
    draggable,
    Loading,
    // Navbar,
    // footerPage,
  },
  data() {
    return {
      check1: true,
      dialogUnsub: false,
      dialogCheckbox: false,
      tab: null,
      items: [],
      search: "",
      sortIndex: 0,
      userData: [],
      loading: false,
      change: false,
      firstname: "",
      lastname: "",
    };
  },
  async created() {
    // this.user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
    await this.getProfile();
    // await this.getUser();
    await this.getAllbrand();
  },
  methods: {
    async getProfile() {
      let _this = this;
      this.$liff.init({ liffId: "1656786311-AEGkzBkO" }).then(() => {
        // alert('in line')
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login();
          console.log("ยังไม่ได้ล้อคอิน");
        } else {
          let that = _this;
          this.$liff.getProfile().then(async function (profile) {
            console.log("ล้อคอินละ", profile);
            that.lineId = profile.userId;
            await that.getUser();
          });
        }
      });
      // let that = this;
      // this.$liff.getProfile().then(async function (profile) {
      //   console.log("ล้อคอินละ", profile);
      //   that.lineId = profile.userId;
      //   await that.getUser();
      // });
    },
    async getUser() {
       const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/findUserByLineId/${this.lineId}`
        // `${process.env.VUE_APP_API}/users/findUserByLineId/U0354ee79b4cd7f2973027952ad0f6fc5`
      );
      if (!response) {
        this.$router.push("Unauthorize");
      } else if (!response.data.data.species_type) {
        this.$router.push("ManageSpecies");
      }
      console.log("user Data", response.data.data);
      this.userData = response.data.data;
      localStorage.setItem("userBiData", Encode.encode(this.userData));
      this.firstname = this.userData.firstname;
      this.lastname = this.userData.lastname;
    },
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brands/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async changeName() {
      // this.change = true;
      this.change = !this.change;
      const data = {
        firstname: this.firstname,
        lastname: this.lastname,
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/${this.userData.id}`,
        data
      );
      console.log("changename", response);
      // this.change = false;
      this.getUser();
    },
    goToManageSpecies() {
      this.$router.push("manageSpecies");
    },
    async unSubscribe(e) {
      // console.log("getPoint");
      this.user = JSON.parse(Decode.decode(localStorage.getItem("userBiData")));
      const data = {
        royalty_status: "Unsubscribe",
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/users/${this.user.id}`,
        data
      );
      console.log("response", response.data);
      // message: "User was updated successfully"
      // response_status: "SUCCESS"
      if (response.data.response_status == "SUCCESS") {
        this.dialogCheckbox = true;
      }
    },

    async getAllbrand() {
      const auth = {
        headers: { Authorization: `Bearer ${this.userData.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbrandpe", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    UpdateBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("EditBrand");
    },
    ViewBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewBrand");
    },
    async DeleteBrand(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/brands/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbrand();
        }
      });
    },
    goToCreate() {
      this.$router.push("createbrand");
    },
  },
};
</script>

<style scoped>
.color-text {
  color: #06204b;
  font-weight: 600;
  font-size: 14px;
  font-family: "OpenSansBold" !important;
}
.detail {
  font-size: 12px;
  color: #4f4f4f;
  font-weight: 700;
}
.font {
  font-family: "OpenSansBold" !important;
}
.div-bottom {
  position: fixed;
  left: auto;
  right: auto;
  bottom: 30px;
  text-align: center;
}
.head-color {
  color: #4f4f4f;
}
.sub-color {
  color: #384e85;
}
.donut {
  color: white;
  background: linear-gradient(268.1deg, #07204b 7.54%, #1a347f 100.72%);
}
</style>
